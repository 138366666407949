(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@fiverr/i18n/singleton"), require("ua-parser-js"), require("classnames"), require("lodash"), require("react"), require("react-dom"), require("prop-types"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["@fiverr/i18n/singleton", "ua-parser-js", "classnames", "lodash", "react", "react-dom", "prop-types", "axios"], factory);
	else if(typeof exports === 'object')
		exports["perseusApp"] = factory(require("@fiverr/i18n/singleton"), require("ua-parser-js"), require("classnames"), require("lodash"), require("react"), require("react-dom"), require("prop-types"), require("axios"));
	else
		root["perseusApp"] = factory(root["i18n"], root["uaParser"], root["classNames"], root["_"], root["React17"], root["ReactDOM17"], root["PropTypes"], root["axios"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__4u8D__, __WEBPACK_EXTERNAL_MODULE__8rrE__, __WEBPACK_EXTERNAL_MODULE_K2gz__, __WEBPACK_EXTERNAL_MODULE_YLtl__, __WEBPACK_EXTERNAL_MODULE_cDcd__, __WEBPACK_EXTERNAL_MODULE_faye__, __WEBPACK_EXTERNAL_MODULE_rf6O__, __WEBPACK_EXTERNAL_MODULE_zr5I__) {
return 
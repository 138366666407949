import React from 'react';
import FiverrBusinessProLogo from '@fiverr-private/icons/logos/fiverr_business_pro_logo';
import LeanBusinessFooter from '@fiverr-private/footer/entries/LeanBusiness';

import './style.scss';

const withWrapperClassNames = (children) => React.cloneElement(children, {
    className: 'registration-wrapper',
    classNameBody: 'registration-wrapper-form-body'
});

export const SignFullPageLayoutWrapper = ({ children }) => (
    <div className='flex flex-col business-accounts-perseus business-registration-full-layout'>
        <div className='flex flex-col' style={{ width: '100%', height: '100%' }}>
            <div className="business-registration-header p-t-24 p-b-24">
                <FiverrBusinessProLogo />
            </div>
            {withWrapperClassNames(children)}
        </div>
        <LeanBusinessFooter/>
    </div>
);

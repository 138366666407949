import { useEffect } from 'react';

export const PARTNERSHIP_SEARCH_URL_SOURCES_MAP = {
    '?utm_source=FiB_partnerships&utm_medium=referrer&utm_campaign=FiB_partnership_AWS_November_2020_bus-y': 'aws'
};

const usePartnershipSourceCheck = () => {
    useEffect(() => {
        if (PARTNERSHIP_SEARCH_URL_SOURCES_MAP[location.search]){
            localStorage.setItem('fb_registration_partnership_source', PARTNERSHIP_SEARCH_URL_SOURCES_MAP[location.search]);
        }
       
    }, [])
};

export default usePartnershipSourceCheck;

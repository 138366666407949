import React, { useEffect } from 'react';
import { filterQueryParams } from '../utils/stringUtils';

const ANIMATION_TIMEOUT = 100;

const useScrollToHash = (location, history) => {

    useEffect(() => {
        if (location && location.hash) {
            const hash = filterQueryParams(location.hash);

            setTimeout(() => document.getElementById(hash)
                && document.getElementById(hash).scrollIntoView({ behavior: 'smooth', block: 'center' }),
            ANIMATION_TIMEOUT);

            history.push({
                ...location,
                hash: ''
            });
        }
    }, [location, history]);
};

export default useScrollToHash;

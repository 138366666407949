import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { withQueryClient } from '@fiverr-private/business_blocks';
import BusinessSignInFullPage from '@fiverr-private/user_session/entries/BusinessSignInFullPage';
import BusinessSignUpFullPage from '@fiverr-private/user_session/entries/BusinessSignUpFullPage';
import InviteeLogin from '../InviteeLogin';
import InviteeOnboardingStepper from '../InviteeOnboardingStepper';
import { SignFullPageLayoutWrapper } from '../Layout/SignFullPageLayoutWrapper';
import { APP_NAME } from '../constants';

const SIGN_IN_SESSION_TYPE = 'sign_in';

const Context = React.createContext({
    userContext: {},
    userState: {},
    organizationProfile: {},
    invitationTokenData: {}
});

export const useAppContext = () => {
    return React.useContext(Context);
};

export const App = ({ userContext, userState, isExistingUser, invitationTokenData = {}, organizationProfile = {} }) => {
    const context = getContext();
    const { queryParameters: { session_type } } = context
    const nonInvitee = !userState.invitee;

    const componentByContext = () => {
        if (nonInvitee) {
            return (
                <SignFullPageLayoutWrapper>
                    {session_type === SIGN_IN_SESSION_TYPE
                        ? <BusinessSignInFullPage source={APP_NAME}/>
                        : <BusinessSignUpFullPage inCodeActivationPro={userContext?.rollouts?.code_activation_pro} source={APP_NAME}/>}
                </SignFullPageLayoutWrapper>
            )
        }

        if (isExistingUser) {
            return <InviteeLogin />;
        }

        return <InviteeOnboardingStepper />;
    };

    return (
        <Context.Provider value={{ userContext, userState, organizationProfile, invitationTokenData }}>
            {componentByContext()}
        </Context.Provider>
    );
};

export default withQueryClient(App);

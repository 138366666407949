import React from 'react';
import { Stepper } from '@fiverr-private/orca';
import {
    Providers,
    useMonitoring
} from '@fiverr-private/business_blocks';
import InviteeRegistration  from '../InviteeRegistration';

const InviteeOnboardingStepper = () => {
    const steps = [
        { id: 'invitee-registration', element: <InviteeRegistration /> }
    ];

    return (
        <Stepper steps={steps}>
            {steps.map(({ element, id }) => {
                const Component = () => {
                    useMonitoring({
                        [Providers.Grafana]: {
                            app: 'user_session',
                            feature: 'invitee_onboarding_stepper',
                            metric: `${id}_show`
                        }
                    });
                    return element;
                };

                return (
                    <Stepper.Step stepId={id} key={id}>
                        <Component />
                    </Stepper.Step>
                );
            })}
        </Stepper>
    );
};

export default InviteeOnboardingStepper;

import axios from 'axios';
import { logger } from '@fiverr-private/obs';
import { BUSINESS_API_PREFIX } from '../constants';

export const updateMemberProfile = async ({ field }) => {
    try {
        return await axios.put(`${BUSINESS_API_PREFIX}/member/profile`, {
            field,
        });
    } catch (error) {
        logger.error(error as any, { flow: 'updateMemberProfile' });
        throw error;
    }
};

export default updateMemberProfile;

import React from 'react';
import { LazyComponent } from '@fiverr-private/orca';
import { LoaderSpinner } from '../LoaderSpinner';
import { logLazyLoadError } from '../../../../utils';
import { FORM_ID } from './constants';

const lazyImport = () => import(/* webpackChunkName: 'LazyBusinessInfoFormComponent' */ './BusinessInfoFormComponent');

export const LazyBusinessInfoFormComponent = (props) => (
    <LazyComponent
        lazyImport={lazyImport}
        shouldImport
        componentProps={props}
        placeholder={<LoaderSpinner asStepPlaceholder />}
        onError={(error) => logLazyLoadError({ moduleName: 'BusinessInfoFormComponent', error })}
    />
);

LazyBusinessInfoFormComponent.FORM_ID = FORM_ID;

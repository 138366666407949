import React from 'react';
import { grey_1000 } from '@fiverr-private/sass/helpers';
import { SpinnerAnimatedIcon } from '@fiverr-private/icons';

import { LOADER_TEST_ID, SIZE_MAP } from './constants';
import { LoaderProps } from './types';

const Loader = ({ size = 'md', color = grey_1000 }: LoaderProps) => (
  <div data-testid={LOADER_TEST_ID}>
    <SpinnerAnimatedIcon size={SIZE_MAP[size]} color={color} />
  </div>
);

Loader.displayName = 'Loader';

export default Loader;

import queryString from 'querystring';

export const underscoreToDash = (str) => str ? str.split('_').join('-') : '';

export const camelToDash = (string) => string.replace(/[\w]([A-Z])/g, (m) => `${m[0]}-${m[1]}`).toLowerCase();

export const filterQueryParams = (params) => params.trim().replace(/^[?#&]/, '');

export const queryParams = (location = {}) => {
    let query = {};
    if (location.search) {
        query = queryString.parse(filterQueryParams(location.search));
    }

    return query;
};

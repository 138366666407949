import axios from 'axios';
import { logger } from '@fiverr-private/obs';
import poll from '../poll';
import { BUSINESS_API_PREFIX } from '../constants';

export const createOrganization = async ({ migrateUser = false }: { migrateUser: boolean }) => {
    try {
        const response: any = await axios
            .post(`${BUSINESS_API_PREFIX}/organization`, { migrate_user: migrateUser })
            .then(poll);

        return {
            organizationId: response?.organization_id as string,
        };
    } catch (error) {
        logger.error(error as any, { flow: 'createOrganization' });
        throw error;
    }
};

import React, { ComponentType } from 'react';
import { logger } from '@fiverr-private/obs';
import { OnError } from '../types';
import ErrorBoundary from '..';

const withErrorBoundary =
  <Props extends JSX.IntrinsicAttributes>(WrappedComponent: ComponentType<Props>, onError?: OnError) =>
  // eslint-disable-next-line react/display-name
  (props: Props) => {
    const defaultErrorHandler = (err) => logger.error(err, { description: 'An error was caught by error boundary' });
    const errorHandler = onError ?? defaultErrorHandler;

    return (
      <ErrorBoundary onError={errorHandler}>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };

export default withErrorBoundary;

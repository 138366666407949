import axios from 'axios';
import { logger } from '@fiverr-private/obs';
import { BUSINESS_API_PREFIX } from '../constants';

export const acceptInvitation = async ({ organizationId, invitationId, needsMigration }) => {
    try {
        return await axios.post(`${BUSINESS_API_PREFIX}/invitation/accept`, {
            organization_id: organizationId,
            invitation_id: invitationId,
            needs_migration: needsMigration,
        });
    } catch (error) {
        logger.error(error as any, {
            message: `Error accepting invitation`,
            organizationId,
            invitationId,
        });
        throw error;
    }
};

import React from 'react';
import classNames from 'classnames';
import FiverrBusinessProLogo from '@fiverr-private/icons/logos/fiverr_business_pro_logo';
import LeanBusinessFooter from '@fiverr-private/footer/entries/LeanBusiness';

import './style.scss';

/**
 * @typedef {object} Props
 * @property {object} [Props.user]
 * @property {string} [Props.className]
 * @property {string} [Props.wrapperClassName]
 */

/**
 * @type {import('react').FC<Props>}
 */
const Layout = ({ children, className, wrapperClassName }) => (
    <div className={classNames('flex flex-col business-accounts-perseus business-registration-layout', wrapperClassName)}>
        <div className={classNames(`flex flex-col`, className)} style={{ flex: 1, overflowY: 'auto' }}>
            <div className="business-registration-header p-t-24 p-b-24">
                <FiverrBusinessProLogo />
            </div>
            <div className="flex flex-justify-center business-registration-body" style={{ flex: 1 }}>
                {children}
            </div>
        </div>
        <LeanBusinessFooter/>
    </div>
);

export default Layout;

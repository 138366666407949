import { getContext } from '@fiverr-private/fiverr_context';

const useIntl = () => {
    const { locale, currency } = getContext();

    const DEFAULT_LOCALE = 'en-US';
    const DEFAULT_CURRENCY = 'USD';

    const formatCurrency = amount => {
        return new Intl.NumberFormat(locale || DEFAULT_LOCALE, {
            style: 'currency',
            currency: currency || DEFAULT_CURRENCY
        }).format(amount);
    };

    return { formatCurrency };
};

export default useIntl;

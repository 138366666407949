import React, { useEffect } from 'react';
import { stats } from '@fiverr-private/obs';
import { logger } from '@fiverr-private/perseus';
import { translate } from '@fiverr-private/i18n-react';
import BusinessInviteeEmailRegistration from '@fiverr-private/user_session/entries/BusinessInviteeEmailRegistration';
import { BUSINESS_TRIGGER_CTA, buildRedirectQueryParameters } from '@fiverr-private/business_success_sign_modal';
import { Stepper, StatusToast } from '@fiverr-private/orca';
import { acceptInvitation } from '@fiverr-private/pro_migration';
import { usePartnershipSourceCheck } from '../../../../shared/hooks';
import { getUserRole } from '../../../../shared/utils/bi/mixpanel/helpers';
import { useAppContext } from '../App';
import { SOURCE, APP_NAME } from '../constants';
import Layout from '../Layout';

import styles from './InviteeRegistration.module.scss';

const grafanaPrefix = `${SOURCE}.${APP_NAME}.fib_guest_invitee_registration`;

/**
 * @param {object} args
 * @param {string} [args.organizationId]
 * @param {string} [args.invitationId]
 */
 const acceptInviteAfterSigning = async ({ organizationId, invitationId } = {}) => {
    try {
        await acceptInvitation({
            organizationId,
            invitationId,
            needsMigration: false
        });

        stats.count(grafanaPrefix, 'accept_invitation_success');

        const url = buildRedirectQueryParameters({
            redirectUrl: '/?redirectedFromOnboarding=true',
            source: APP_NAME,
            type: BUSINESS_TRIGGER_CTA.INVITE
        });
        window.location.replace(url);
    } catch (error) {
        StatusToast.raise({
            message: translate('business_blocks.errors.something_went_wrong'),
            theme: StatusToast.THEMES.ERROR,
        });

        stats.count(grafanaPrefix, 'accept_invitation_failed');
        logger.error(error, { message: `Could not accept invite after signing-in for invitationId ${invitationId} ` });
    }
};

const InviteeRegistration = () => {
    const { userState, organizationProfile, invitationTokenData } = useAppContext();
    const { next } = Stepper.useContext();

    usePartnershipSourceCheck();

    useEffect(() => {
        stats.count(grafanaPrefix, 'invitee_registration_show');
    }, []);

    return (
        <div className={styles.container}>
            <Layout wrapperClassName={styles.formWrapper}>
                <div style={{ maxWidth: 478 }}>
                    <BusinessInviteeEmailRegistration
                        initialValues={invitationTokenData.email ? { email: invitationTokenData.email } : {}}
                        organizationName={organizationProfile.name}
                        biEnrichmentParams={{ role: getUserRole(userState.invitee) }}
                        source={APP_NAME}
                        afterSigningSuccess={async () => {
                            stats.count(grafanaPrefix, 'user_created_success');
                            await acceptInviteAfterSigning({
                                invitationId: invitationTokenData.invitationId,
                                organizationId: invitationTokenData.teamId,
                            });

                            next();
                        }}
                    />
                </div>
            </Layout>
            <div className={styles.asideWrapper}>
                <div className={styles.image}/>
                <div className="t-a-center tbody-4 text-bold co-text-darkest">{translate('business_user_session.invite.image_title', {
                    templates: { domaine: (text) => <i className="font-domaine">{text}</i> }
                })}</div>
            </div>
        </div>
    );
};

export default InviteeRegistration;

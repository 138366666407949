import { noop } from 'lodash';
import { logger, stats } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';

export const logLazyLoadError = ({
    moduleName,
    error,
    onLoadError = noop,
}: {
    moduleName: string;
    error: any;
    onLoadError?: (error: any) => void;
}) => {
    logger.warn(`Failed to lazy load ${moduleName}`, { error });
    onLoadError(error);
    stats.count(`business_acquisition_sphinx.pro_migration.${moduleName}`, 'lazy_import_failed');
};

export const deprecatedLogger = ({
    componentName,
    message,
    ...data
}: { componentName: string; message: string } & Record<string, unknown>) => {
    logger.warn('Deprecated using in pro_migration package', {
        details: {
            url: getContext().url,
            componentName,
            message,
            ...data,
        },
    });
};

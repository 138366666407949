import React, { FC } from 'react';
import classnames from 'classnames';
import { Loader } from '@fiverr-private/feedback';

import styles from './LoaderSpinner.module.scss';

export interface LoaderSpinnerProps {
    className?: string;
    asStepPlaceholder?: boolean;
}

export const LoaderSpinner: FC<LoaderSpinnerProps> = ({ className = '', asStepPlaceholder = false }) => (
    <div
        className={classnames(styles.container, {
            [className]: className,
            [styles.asStep]: asStepPlaceholder,
        })}
    >
        <Loader size="lg" />
    </div>
);

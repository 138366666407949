import { Component } from 'react';
import { OnError, ErrorBoundaryProps, ErrorBoundaryState } from './types';

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
    this.onError = props.onError;
  }

  override componentDidCatch(err: Error): void {
    this.onError(err);
  }

  onError: OnError;

  override render() {
    const { hasError } = this.state;

    if (hasError) {
      return null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import React from 'react';
import { BusinessInviteeSignInForm } from '@fiverr-private/user_session';
import { usePartnershipSourceCheck } from '../../../../shared/hooks';
import { getUserRole } from '../../../../shared/utils/bi/mixpanel/helpers';
import Layout from '../Layout';
import { useAppContext } from '../App';

const InviteeLogin = () => {
    const { userState, organizationProfile, invitationTokenData } = useAppContext();

    usePartnershipSourceCheck();

    return (
        <Layout>
            <div style={{ maxWidth: 400 }}>
                <BusinessInviteeSignInForm
                    initialValues={invitationTokenData.email ? { login: invitationTokenData.email } : {}}
                    organizationName={organizationProfile.name}
                    biEnrichmentParams={{ role: getUserRole(userState.invitee) }}
                />
            </div>
        </Layout>
    );
};

export default InviteeLogin;

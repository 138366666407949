import { getContext } from '@fiverr-private/fiverr_context';
import { localStorage } from '@fiverr-private/futile';
import pathfinder from '@fiverr-private/pathfinder';
import { DEFAULT_CTA_DATA } from '../../constants';
import { BUSINESS_TRIGGER_CTA, CtaData } from '../../types';
import { FIB_SUCCESS_REGISTRATION_URL_FLAG, FIB_CTA_EVENT_NAME, CTA_DATA_FLAG } from './constants';

const buildCtaEvent = (type: BUSINESS_TRIGGER_CTA, source = '') => `${FIB_CTA_EVENT_NAME}:${type}:${source}`;

const getRegistrationKey = (organizationId) => `FibUserJustRegistered:${organizationId}`;

const getWindow = () => typeof window !== 'undefined';

class SuccessModalState {
    triggerCtaEvent = (ctaData = DEFAULT_CTA_DATA) => {
        if (!getWindow()) {
            return;
        }
        const { type, data, source } = ctaData;
        const event = new CustomEvent(buildCtaEvent(type, source), {
            detail: data,
        });
        window.dispatchEvent(event);
    };

    registerCtaEvent = ({ type, source, callback }) => {
        if (!getWindow()) {
            return;
        }
        window.addEventListener(buildCtaEvent(type, source), callback, { once: true });
    };

    removeCtaEvent = ({ type, source, callback }) => {
        if (!getWindow()) {
            return;
        }
        window.removeEventListener(buildCtaEvent(type, source), callback);
    };

    setShouldShowSuccessSignModal = (organizationId, triggerCtaData = DEFAULT_CTA_DATA) => {
        if (organizationId && getWindow()) {
            localStorage.set(getRegistrationKey(organizationId), triggerCtaData);
        }
    };

    isModalAvailable = () => {
        const { experience, queryParameters = {} } = getContext();
        const isCertified = location.pathname.startsWith('/certified');

        return (
            !isCertified &&
            !!experience?.isBusiness &&
            typeof queryParameters[FIB_SUCCESS_REGISTRATION_URL_FLAG] !== 'undefined'
        );
    };

    getSuccessSignModalState = () => {
        const { organizationId, queryParameters = {} } = getContext();
        const shouldShow = this.isModalAvailable();
        const storageState: CtaData | undefined =
            organizationId && getWindow() ? localStorage.get(getRegistrationKey(organizationId)) : undefined;
        const urlType = queryParameters[FIB_SUCCESS_REGISTRATION_URL_FLAG] as BUSINESS_TRIGGER_CTA | undefined;
        const urlCtaData = queryParameters[CTA_DATA_FLAG] as string | undefined;
        const source = queryParameters.source as string;

        let ctaData: CtaData = { type: BUSINESS_TRIGGER_CTA.DEFAULT, source, data: {} };

        const preferUrlValue = [
            BUSINESS_TRIGGER_CTA.COLLABORATING,
            BUSINESS_TRIGGER_CTA.INVITE,
            BUSINESS_TRIGGER_CTA.AGGRESSIVE,
            BUSINESS_TRIGGER_CTA.PAYMENT,
        ];

        if (urlType && preferUrlValue.includes(urlType)) {
            ctaData = {
                type: urlType,
                source,
                data: {},
            };

            if (urlType === BUSINESS_TRIGGER_CTA.COLLABORATING) {
                ctaData.data = {
                    href: pathfinder('business_members_page', {}, { query: { source: source || '' } }),
                };
            }
        } else if (urlCtaData || storageState) {
            ctaData = urlCtaData ? JSON.parse(urlCtaData) : storageState;
        }

        return {
            shouldShow,
            urlType,
            ctaData,
        };
    };

    cleanUpShowSuccessSignModal = () => {
        const { organizationId } = getContext();
        if (organizationId && getWindow()) {
            localStorage.remove(getRegistrationKey(organizationId));
        }
    };
}

export const successModalState = new SuccessModalState();

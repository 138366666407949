import axios, { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { logger } from '@fiverr-private/obs';
import { BUSINESS_ACCOUNTS_AJAX_PREFIX } from './constants';

const MAX_REQUEST_COUNT = 10;

const STATUS = {
    IN_PROGRESS: 'IN_PROGRESS',
    READY: 'READY',
};

const ACCEPTED = 202;

/**
 * Skips errors triggered by bad perseus responses
 * as errors have been logged on BE
 *
 * @param {Object} error - Error object
 * @param {Object} data - Additional data to log
 */
const handleRequestError = (error: any, data?: object) => {
    if (!get(error.response, 'data.body.error')) {
        logger.error(error, data);
    }

    throw error;
};

/**
 * The method that executes the polling. Call the endpoint less than set number of times and resolve/reject afterwards
 */
const pollRec = (
    response: AxiosResponse,
    requestCount: number,
    resolve: (arg: any) => any,
    reject: (arg: any) => any
) => {
    const { data, status } = response;

    if (status !== ACCEPTED) {
        return reject(Error('Polling is valid only for requests with status 202 ACCEPTED'));
    }

    if (requestCount > MAX_REQUEST_COUNT) {
        return reject(Error('Polling timed out'));
    }

    axios
        .get(`${BUSINESS_ACCOUNTS_AJAX_PREFIX}/poll?pollingId=${data.polling_id}`)
        .then(({ data }) => {
            const { polling_status: status, message } = data;

            if (status === STATUS.IN_PROGRESS) {
                setTimeout(() => {
                    pollRec(response, ++requestCount, resolve, reject);
                }, 300);
            } else if (status === STATUS.READY) {
                resolve(message);
            }
        })
        .catch((error) => {
            handleRequestError(error);
            throw error;
        });
};

/**
 * The method receives an AxiosResponse, expecting its data to include a polling_id.
 *
 * @throws Error
 */
export default (response: AxiosResponse) =>
    new Promise((resolve, reject) => {
        pollRec(response, 0, resolve, reject);
    });

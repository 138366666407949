import axios from 'axios';
import { getContext } from '@fiverr-private/fiverr_context';
import { logger } from '@fiverr-private/obs';
import { BUSINESS_API_PREFIX } from '../constants';

interface Payload {
    numberOfEmployees: string | number;
    organizationId: string;
    companyName: string;
}

export const UPDATE_BUSINESS_INFO_URL = `${BUSINESS_API_PREFIX}/business_info`;

export const updateBusinessProfile = async ({ numberOfEmployees, organizationId, companyName }: Payload) => {
    const { userId } = getContext();
    const params = {
        user_id: userId,
        organization_id: organizationId,
        business_info: {
            company_name: companyName,
            number_of_employees: numberOfEmployees,
        },
    };

    try {
        return await axios.post(UPDATE_BUSINESS_INFO_URL, params);
    } catch (error) {
        logger.error(error as any, { flow: 'updateBusinessProfile' });
        throw error;
    }
};

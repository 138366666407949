import { getContext } from '@fiverr-private/fiverr_context';

const useI18n = () => {
    const { locale } = getContext();

    return {
        t: (key, options) => i18n.t(key, { ...options, $scope: locale })
    };
};

export default useI18n;

export enum BUSINESS_TRIGGER_CTA {
    DEFAULT = 'default',
    CONTACT = 'contact',
    PURCHASE = 'purchase',
    COLLABORATING = 'collaborating',
    INVITE = 'invitation',
    AGGRESSIVE = 'aggressive'
}

export interface CtaData {
    type: BUSINESS_TRIGGER_CTA;
    source?: string;
    data: Record<string, unknown>;
}

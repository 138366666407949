import { CtaData, BUSINESS_TRIGGER_CTA } from './types';

export const DEFAULT_CTA_DATA: CtaData = {
    type: BUSINESS_TRIGGER_CTA.DEFAULT,
    source: '',
    data: {},
};

export const PAGE_SOURCE = {
    // Used on LOHP user_questions
    SEAMLESS_GIG_PAGE: 'seamless_reg_gig_page',
    // Used on LOHP user_questions
    SEAMLESS_LISTINGS: 'seamless_reg_listing',
};

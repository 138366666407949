import { getContext } from '@fiverr-private/fiverr_context';
import { BUSINESS_TRIGGER_CTA } from '../types';
import { FIB_SUCCESS_REGISTRATION_URL_FLAG } from './SuccessModalState';

export const buildRedirectQueryParameters = (data: {
  redirectUrl?: string;
  source?: string;
  type?: BUSINESS_TRIGGER_CTA;
}) => {
  const contextUrl = getContext().url;
  const { pathname, search } = contextUrl ? new URL(contextUrl) : window.location;
  const url = data.redirectUrl
    ? new URL(data.redirectUrl, window.location.origin)
    : new URL(pathname + search, window.location.origin);

  url.searchParams.set(FIB_SUCCESS_REGISTRATION_URL_FLAG, data.type || 'true');

  if (data.source) {
    url.searchParams.set('source', data.source);
  }

  return url.toString();
};
